<template>
    <div class="contenedor-marcojuridico">
        <div class="marcojuridico-titulo">
            <h1>Marco Jurídico</h1>
        </div>
        <div class="marcojuridico-contenido">
            <h3>Internacional</h3>
            <ol type="1">
                <li><a href="http://cedoc.inmujeres.gob.mx/documentos_download/100039.pdf" target="_blank">Convención sobre la Eliminación de Todas las Formas de Discriminación contra la Mujer.</a></li>
                <li><a href="https://www.gob.mx/cms/uploads/attachment/file/398069/Observaciones_finales_9o_Informe_M_xico_ante_la_CEDAW.pdf" target="_blank">Observaciones Finales sobre el Noveno Informe Periódico de México.</a></li>
                <li><a href="https://www.te.gob.mx/transparencia/media/files/cf83ab8278fbeda.pdf" target="_blank">Convención Interamericana para Prevenir, Sancionar y Erradicar la Violencia contra la Mujer “Convención de Belém Do Pará”.</a></li>
                <li><a href="https://www.oas.org/es/mesecvi/docs/TercerInformeHemisferico-ES.pdf" target="_blank">Tercer Informe Hemisférico sobre la Implementación de la Convención de Belém Do Pará.</a></li>
                <li><a href="https://www.unwomen.org/-/media/headquarters/attachments/sections/csw/bpa_s_final_web.pdf?la=es&vs=755" target="_blank">Plataforma de Acción de Beijing.</a></li>
            </ol>
            <h3>Nacional</h3>
            <ol type="1">
                <li><a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGAMVLV_180321.pdf" target="_blank">Ley General de Acceso de las Mujeres a una Vida Libre de Violencia.</a></li>
                <li><a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LGAMVLV.pdf" target="_blank">Reglamento de la Ley General de Acceso de las Mujeres a una Vida Libre de Violencia.</a></li>
                <li><a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGV_061120.pdf" target="_blank">Ley General de Víctimas.</a></li>
                <li><a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LGV.pdf" target="_blank">Reglamento de la Ley General de Víctimas.</a></li>
                <li><a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGV_061120.pdf" target="_blank">Ley General para Prevenir, Sancionar y Erradicar los Delitos en materia de Trata de Personas y para la Protección y Asistencia a las Víctimas de estos Delitos.</a></li>
                <li><a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LGPSEDMTP.pdf" target="_blank">Reglamento de la Ley General para Prevenir, Sancionar y Erradicar los Delitos en materia de Trata de Personas y para la Protección y Asistencia a las Víctimas de estos Delitos.</a></li>
            </ol>
            <h3>Estatales</h3>
            <ol type="1">
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/leyes/pdf/LMUJERVVEM.pdf" target="_blink">Ley de Acceso de las Mujeres a una Vida Libre de Violencia para el estado de Morelos. </a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/reglamentos_estatales/pdf/RLMUJERVVEM.pdf" target="_blink">Reglamento de la Ley de Acceso de las Mujeres a una Vida Libre de Violencia para el estado de Morelos.</a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/leyes/pdf/LREPARAEM.pdf" target="_blink">Ley de Víctimas del estado de Morelos.</a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/decretos_ejecutivo/pdf/DVIOLMUJMO.pdf" target="_blink">Decreto por el que se integra el Sistema Estatal para Prevenir, Atender, Sancionar y Erradicar la Violencia contra las Mujeres.</a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/leyes/pdf/LSERVIDOREM.pdf" target="_blink">Ley estatal de Responsabilidades de los Servidores Públicos.</a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/reglamentos_estatales/pdf/PCOVIDMUJERESVIOLENCIA.pdf" target="_blink">Plan estatal de Acciones Emergentes para Garantizar Atención y Seguridad a Mujeres Adoloescentes y niñas durante contingencia por COVID.</a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/reglamentos_estatales/pdf/RORDMUJVMO.pdf" target="_blink">Reglamento para la aplicación de las Órdenes de Protección en materia de Violencia contra las Mujeres y Violencia Familiar para el estado de Morelos</a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/decretos_ejecutivo/pdf/DECSEPASEVIOLENCIA2021.pdf" target="_blink">Decreto por el que se determina la publicación del Programa Estatal para Prevenir, Atender, Sancionar y Erradicar la Violencia contra las Mujeres.</a></li>
                <li><a href="http://marcojuridico.morelos.gob.mx/archivos/varios/pdf/MODATENUNICO.pdf" target="_blink">Modelo de Atención Único.</a></li>
                <li>
                    <p>Programa Estatal para Prevenir, Atender, Sancionar y Erradicar la Violencia contra las Mujeres</p>
                    <ol type="1">
                        <li><a href="http://periodico.morelos.gob.mx/obtenerPDF/2021/5911_3A.pdf" target="_blink"> Tercera Sección</a></li>
                        <li><a href="http://periodico.morelos.gob.mx/obtenerPDF/2021/5911_4A.pdf" target="_blink"> Cuarta sección </a></li>
                        <li><a href="http://periodico.morelos.gob.mx/obtenerPDF/2021/5911_5A.pdf" target="_blink"> Quinta sección</a></li>
                    </ol>
                </li>
            </ol>
        </div>
        <button class="btn-regresar"> <router-link to="/dashboard">Regresar</router-link> </button>
    </div>
</template>